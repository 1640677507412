import { html } from './html';
import { initCarousel } from './initCarousel';

declare const $: any;

$(document).ready(async () => {
  const Slide = ({ id, heading, image, title, link, bgColor, textColor }) => {
    return html`
      <section id="${id}">
        <div class="slide-wrapper max-center">
          <img src="${image}" alt="carousel" />
          <div class="content-wrapper">
            <article style="color: ${textColor}">
              <div>
                <h1 class="hero">${heading}</h1>

                <h4>${title}</h4>
                <h4 class="cta">
                  <a href="${link}"> Read Their Story </a>
                </h4>
              </div>
            </article>
          </div>
        </div>
        <div class="divider" style="color: var(--neutral4)"></div>
        <svg
          class="svg-cloud"
          viewBox="0 0 55% 564"
          style="--bg-fill: ${bgColor}"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M169.741 759.272C15.243 922.961-36.3248 1168.98 58.253 1388.92c96.806 225.11 318.773 357.89 549.632 352 7.394 34.19 18.176 68.17 32.515 101.51 119.914 278.85 443.18 407.69 722.02 287.78 188.43-81.03 308.36-254.91 329.33-445.2 83.27.98 168.09-15.06 249.52-50.08 311.65-134.02 455.65-495.31 321.63-806.966-80.05-186.136-241.16-312.468-424.35-355.483 64.33-137.257 71.92-300.354 7.33-450.5634-84.91-197.4496-271.78-319.6866-472.64-331.6526 1.53-95.198-16.58-192.25-56.63-285.384-151.65-352.662-560.485-515.611-913.146-363.954C50.8024-807.417-112.144-398.586 39.5123-45.9238 57.8412-3.30176 79.9267 36.5493 105.215 73.4087.151689 216.393-28.5253 409.714 46.637 584.497c29.4122 68.395 71.823 127.202 123.104 174.775z"
          />
        </svg>
      </section>
    `;
  };

  await initCarousel(Slide, 'desktop-carousel-wrapper', 'desktop-logo-section');
});
