import { fetchData } from './fetchData';
import { html } from './html';

declare const $: any;

export const LogoItem = ({ logo, id }) => {
  return html`<button data-index="${id}">${logo}</button> `;
};

export const initCarousel = async (
  component,
  carouselSelector,
  logoSelector,
) => {
  const data = await fetchData('/data/homeCarousel.json');

  const carouselWrapper = document.getElementById(carouselSelector);

  const carouselContent = data.map(component);

  carouselWrapper.innerHTML = carouselContent.join('');

  const logoContainer = document.getElementById(logoSelector);

  const $carouselWrapper = $(`#${carouselSelector}`);

  const handleNavigation = e => {
    const { index } = e.currentTarget.dataset;
    return $carouselWrapper.slick('slickGoTo', index);
  };
  const $logoContainer = $(logoContainer);

  const renderLogos = (logoContainer, logoObjs) => {
    const logos = logoObjs.map(LogoItem);

    logoContainer.innerHTML = logos.join('');

    logoContainer.querySelectorAll('button').forEach(button => {
      button.addEventListener('click', handleNavigation);
    });
  };

  renderLogos(logoContainer, data);

  $logoContainer.slick({
    swipe: false,
    arrows: false,
    infinite: true,
    dots: false,
    slidesToShow: 1,
    asNavFor: `#${carouselSelector}`,
    variableWidth: true,
    focusOnSelect: true,
  });

  $carouselWrapper.slick({
    swipe: true,
    arrows: false,
    asNavFor: `#${logoSelector}`,
  });
};
