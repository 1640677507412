import { html } from './html';
import { initCarousel } from './initCarousel';

declare const $: any;

const Slide = ({ id, heading, image, title, link, bgColor, textColor }) => {
  return html`
    <section class="carousel-slide" id="${id}" style="--bg-fill: ${bgColor}">
      <div class="slide-wrapper">
        <div class="image-wrapper">
          <img alt="carousel ${image}" src="${image}" />
        </div>
        <div class="mobile-content-wrapper">
          <article style="color: ${textColor}">
            <h1 class="hero">${heading}</h1>
            <h4>${title}</h4>
            <h4 class="cta">
              <a href="${link}"> Read Their Story </a>
            </h4>
          </article>
          <svg class="mobile-clouds" fill="none" viewBox="0 0 360 417">
            <path
              clip-rule="evenodd"
              d="M582.668 116.404C706.415 14.3021 888.688-18.0034 1048.88 47.4659c164.23 67.1221 257.8 217.6221 248.74 372.7831 26.44 5.302 52.66 13.125 78.28 23.596 203.28 83.077 291.93 301.394 198.01 487.626-63.2 125.319-192.97 204.179-333.04 217.039-1.44 54.76-14.94 110.29-41.77 163.49-104.97 208.14-374.233 301.6-601.421 208.75-137.355-56.14-227.882-167.52-254.801-292.83-103.083 43.06-224.696 47.15-335.18516 1.99C-136.772 1170.87-223.342 1043.52-227.628 907.927c-71.785.786-144.699-12.212-214.139-40.592-257.082-105.068-369.198-381.175-250.418-616.703C-573.405 15.1041-268.709-90.6547-11.628 14.4131c32.6291 13.3353 62.9231 29.426 90.706 47.8392C186.203-5.26504 327.702-21.892 453.806 29.646c51.035 20.858 94.394 50.7738 128.862 86.758z"
              fill-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </section>
  `;
};

$(document).ready(async () => {
  await initCarousel(Slide, 'mobile-carousel-wrapper', 'mobile-logo-section');
});
